import { createGlobalStyle } from 'styled-components';

import AktivGroteskCorpLightWoff from '../fonts/AktivGroteskCorp-Light.woff';
import AktivGroteskCorpLightWoff2 from '../fonts/AktivGroteskCorp-Light.woff2';

import AktivGroteskCorpRegularWoff from '../fonts/AktivGroteskCorp-Regular.woff';
import AktivGroteskCorpRegularWoff2 from '../fonts/AktivGroteskCorp-Regular.woff2';

import AktivGroteskCorpMediumWoff from '../fonts/AktivGroteskCorp-Medium.woff';
import AktivGroteskCorpMediumWoff2 from '../fonts/AktivGroteskCorp-Medium.woff2';

import AktivGroteskCorpBoldWoff from '../fonts/AktivGroteskCorp-Bold.woff';
import AktivGroteskCorpBoldWoff2 from '../fonts/AktivGroteskCorp-Bold.woff2';

export const theme = {
    fonts: {
        main: 'Aktiv Grotesk Corp, -apple-system, sans-serif, Arial',
    },
    colors: {
        darkGrey: '#111111',
        sharkGrey: '#2A2A2F',
        warmRed: '#F22C36',
        luminousGrey: '#E6E6E6',
        grey: '#929296',
        white: '#FFFFFF',
        green: '#21C354',
    },
    breakpoints: {
        mobile: 'only screen and (max-width: 576px)',
        tablet: 'only screen and (max-width: 768px)',
        tabletPlus: 'only screen and (max-width: 1024px)',
        notebook: 'only screen and (max-width: 1200px)',
        notebookPlus: 'only screen and (max-width: 1440px)',
    },
};

export const GlobalStyles = createGlobalStyle`
    //-----------------
    //Fonts
    //-----------------
    @font-face {
        font-family: 'Aktiv Grotesk Corp';
        src: local('AktivGroteskCorp-Light'),
            url(${AktivGroteskCorpLightWoff2}) format('woff2'),
            url(${AktivGroteskCorpLightWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Aktiv Grotesk Corp';
        src: local('AktivGroteskCorp-Regular'),
            url(${AktivGroteskCorpRegularWoff2}) format('woff2'),
            url(${AktivGroteskCorpRegularWoff}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Aktiv Grotesk Corp';
        src: local('AktivGroteskCorp-Medium'),
            url(${AktivGroteskCorpMediumWoff2}) format('woff2'),
            url(${AktivGroteskCorpMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Aktiv Grotesk Corp';
        src: local('AktivGroteskCorp-Bold'),
            url(${AktivGroteskCorpBoldWoff2}) format('woff2'),
            url(${AktivGroteskCorpBoldWoff}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    
    //-----------------
    //Common
    //-----------------
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }
    
    body,html {
        background-color: hsl(0, 0%, 100%);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        color: ${({ theme }) => theme.colors.sharkGrey};
        font-family: ${({ theme }) => theme.fonts.main}!important;
        font-size: 16px;
        line-height: 16px;
    }
    body {
        background-color: hsl(0, 0%, 100%);
        pointer-events: inherit!important;
    }
    html[data-direction="down"] .header-animation {
      transform: translate3d(0,-100%,0);
    }    
    
    //-----------------
    //Hide
    //-----------------
    .hide {
        display:none;
    }
    
    //-----------------
    //Swiper
    //-----------------
    .swiper-wrapper {
        transition-timing-function: ease-in-out;
    }
    .swiperNav {
        svg path {
            transition: stroke 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
        &:hover svg path {
            stroke: ${({ theme }) => theme.colors.warmRed};
        }
    }
    
    //-----------------
    //Reset Buttons
    //-----------------
    button {
        background: none;
        border: none;
        padding:0;
        margin:0;
        line-height: 100%;
    }
    
    //-----------------
    //Wrappers
    //-----------------
    .contentWrapper {
        padding: 0 3rem 0;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            padding: 0 1rem 0;
        }
    }
    
    //-----------------
    //Spacing
    //-----------------
    .space-top-12 {
        margin-top: 0.75rem;
    }
    .space-top-16 {
        margin-top: 1rem;
    }
    .space-top-20 {
        margin-top: 1.25rem;
    }
    .space-top-24 {
        margin-top: 1.5rem;
    }
    .space-top-32 {
        margin-top: 2rem;
    }
    .space-top-40 {
        margin-top: 2.5rem;
    }
    .space-top-48 {
        margin-top: 3rem;
    }
    .space-top-52 {
        margin-top: 3.25rem;
    }
    .space-top-60 {
        margin-top: 3.75rem;
    }
    .space-top-72 {
        margin-top: 4.5rem;
    }
    .space-top-80 {
        margin-top: 5rem;
    }
    .space-top-100 {
        margin-top: 6.25rem;
    }
    .space-top-120 {
        margin-top: 7.5rem;
    }
    .space-top-160 {
        margin-top: 10rem;
    }
    .space-top-240 {
        margin-top: 15rem;
    }
    //-----------------
    .space-bottom-8 {
        margin-bottom: 0.5rem;
    }
    .space-bottom-12 {
        margin-bottom: 0.75rem;
    }
    .space-bottom-16 {
        margin-bottom: 1rem;
    }
    .space-bottom-20 {
        margin-bottom: 1.25rem;
    }
    .space-bottom-24 {
        margin-bottom: 1.5rem;
    }
    .space-bottom-32 {
        margin-bottom: 2rem;
    }
    .space-bottom-40 {
        margin-bottom: 2.5rem;
    }
    .space-bottom-48 {
        margin-bottom: 3rem;
    }
    .space-bottom-52 {
        margin-bottom: 3.25rem;
    }
    .space-bottom-60 {
        margin-bottom: 3.75rem;
    }
    .space-bottom-80 {
        margin-bottom: 5rem;
    }
    .space-bottom-100 {
        margin-bottom: 6.25rem;
    }
    .space-bottom-120 {
        margin-bottom: 7.5rem;
    }
    .space-bottom-160 {
        margin-bottom: 10rem;
    }
    
    //-----------------
    //Links
    //-----------------
    a {
        color: ${({ theme }) => theme.colors.sharkGrey};
        text-decoration: none;
        transition: color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    a:hover {
        color: ${({ theme }) => theme.colors.warmRed};
        text-decoration: underline;
    }

    //-----------------
    //Text styles    
    //-----------------
    body h1, body h2, body h3, body h4 {
        margin: 0;
        margin-bottom: 0;
        padding: 0;
        line-height: 100%;
        letter-spacing: -0.8px;
    }
    h1 {
        font-size: 4.75rem;
        line-height: 4.5rem;
        font-weight: 300;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 2.75rem;
            line-height: 2.5rem;
        }
    }
    h2 {
        font-size: 2.5rem;
        line-height: 2.5rem;
        font-weight: 400;
        @media ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 2rem;
            line-height: 2rem;
        }
    }
    h3 {
        font-size: 1.75rem;
        line-height: 2rem;
        font-weight: 400;
    }
    h3.subheading {
          font-size: 1.25rem;
          line-height: 1.25rem;
          font-weight: 500;
    }
    .subheading {
        font-weight: 500;
    }
    //-----------------
    body p {
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin:0;
    }
    body p.small {
        font-size: 1rem;
        line-height: 1rem;
    }
    
    body p.subheading {
        font-size: 1.5rem;
        line-height: 1.75rem;
        font-weight: 400;
    }
    //-----------------
    .text-20 {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
    //-----------------
    .font-light {
        font-weight: 300;
    }
    .font-medium {
        font-weight: 500;
    }
    
    //-----------------
    //Colors
    //-----------------
    .luminousGrey {
        color: ${({ theme }) => theme.colors.luminousGrey}
    }
    .luminousGrey-bg {
        background: ${({ theme }) => theme.colors.luminousGrey}
    }
    .grey {
        color: ${({ theme }) => theme.colors.grey}
    }
    .sharkGrey-bg {
        background: ${({ theme }) => theme.colors.sharkGrey}
    }
    
    //-----------------
    //Opacity
    //-----------------
    .opacity-50 {
        opacity: 0.5;
    }
    
    //-----------------
    //Animation
    //-----------------
    .delay-250 {
        transition-delay: 250ms;
    }
    .delay-450 {
        transition-delay: 450ms;
    }
    .delay-650 {
        transition-delay: 650ms;
    }
    .delay-850 {
        transition-delay: 850ms;
    }
    .delay-1050 {
        transition-delay: 1050ms;
    }
    .delay-1250 {
        transition-delay: 1250ms;
    }
    .delay-1500 {
        transition-delay: 1500ms;
    }
    .delay-2000 {
        transition-delay: 2000ms;
    }
    .duration-1000 {
        transition-duration: 1000ms;
    }
    .duration-1500 {
        transition-duration: 1500ms;
    }
    
    //-----------------
    //Smooth Scroll
    //-----------------
    .is-inview {
        .line-animate {
            .animate-block{
                transform: translate(0%);
                opacity: 1;
            }
        }
        .text-animation {
            transition-delay: 450ms;
            transform: none;
            opacity: 1;
        }
        
    }
    .line-animate {
        overflow: hidden;
        .animate-block{
            transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
            opacity: 0;
            transform: translate(0,120%);
        }
    }
    .text-animation {
        opacity: 0;
        transform: translateY(100%) rotateX(-60deg);
        transform-origin: center top;
        transform-style: preserve-3d;
        transition: opacity 1000ms cubic-bezier(.215,.61,.355,1), transform 1000ms cubic-bezier(.215,.61,.355,1);
    }
    
    //-----------------
    //Forms
    //-----------------
    .form-block, .form-block form > div {
        position:relative
    }
    .input {
        line-height: 1.25rem;
        color: ${({ theme }) => theme.colors.sharkGrey};
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.colors.sharkGrey};
        border-radius:0;
        padding: 1rem 0;
        display: block;
        width: 100%;
        margin-bottom: 2.5rem;
        outline: 0;
        transition: border-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    .input:focus {
        border-color: ${({ theme }) => theme.colors.warmRed};
    }
    .input.isInvalid {
        border-color: ${({ theme }) => theme.colors.warmRed};
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 18.5882L5.41174 4L3.99998 5.41176L18.5882 20L20 18.5882Z' fill='%23D21E4F'/%3E%3Cpath d='M5.41178 20L20 5.41174L18.5882 3.99998L4.00002 18.5882L5.41178 20Z' fill='%23D21E4F'/%3E%3C/svg%3E%0A");
        background-size: 16px 16px;
        background-position: right 0 center;
        background-repeat: no-repeat;
    }
    .input.isInvalid:focus {
        border-bottom: 2px solid ${({ theme }) => theme.colors.warmRed};
    }
    .form-block .error {
        position: absolute;
        bottom: 1rem;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.warmRed};
    }
    .checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .checkbox + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }
    .checkbox + label::before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid ${({ theme }) => theme.colors.sharkGrey};
        margin-right: 0.5rem;
        border-radius: 10rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    .checkbox.isInvalid + label::before {
        border: 2px solid ${({ theme }) => theme.colors.warmRed};
        
    }
    .checkbox:checked + label::before {
        border-color: ${({ theme }) => theme.colors.green};
        background-color: ${({ theme }) => theme.colors.green};
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
    .checkbox:not(:disabled):not(:checked) + label:hover::before {
        border-color: ${({ theme }) => theme.colors.sharkGrey};
    }
    .privacyAgreement {
        padding-bottom: 1.75rem;
        margin-bottom: 1.5rem;
    }
    .form-block .loading{
        position: absolute !important;
        z-index: 999;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.75);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form-block .loading img{
        width: 4rem;
        height: 4rem;
    }
    
    //-----------------
    //Swal
    //-----------------
    .swal2-container .swal2-popup {
        border-radius: none;
    }
    .swal2-container .swal2-styled.swal2-confirm {
        background: ${({ theme }) => theme.colors.warmRed}
    }
    
    //-----------------
    //Locomotive
    //-----------------
    html.has-scroll-smooth {
        overflow: hidden;
    }
    
    html.has-scroll-dragging {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    .has-scroll-smooth body {
        overflow: hidden;
    }
    
    .has-scroll-smooth [data-scroll-container] {
        min-height: 100vh;
    }
    
    [data-scroll-direction='horizontal'] [data-scroll-container] {
        height: 100vh;
        display: inline-block;
        white-space: nowrap;
    }
    
    [data-scroll-direction='horizontal'] [data-scroll-section] {
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        height: 100%;
    }
    
    .c-scrollbar {
        position: absolute;
        right: 0;
        top: 0;
        width: 11px;
        height: 100%;
        z-index: 9999;
        background: #fff;
        transform-origin: center right;
        transition: transform 0.3s, opacity 0.3s;
        opacity: 0;
    }
    .c-scrollbar:hover {
        transform: scaleX(1.45);
    }
    .c-scrollbar:hover,
    .has-scroll-scrolling .c-scrollbar,
    .has-scroll-dragging .c-scrollbar {
        opacity: 1;
    }
    [data-scroll-direction='horizontal'] .c-scrollbar {
        width: 100%;
        height: 10px;
        top: auto;
        bottom: 0;
        transform: scaleY(1);
    }
    [data-scroll-direction='horizontal'] .c-scrollbar:hover {
        transform: scaleY(1.3);
    }
    
    .c-scrollbar_thumb {
        position: absolute;
        top: 0;
        right: 0;
        background-color: black;
        opacity: 0.5;
        width: 7px;
        border-radius: 10px;
        margin: 2px;
        cursor: -webkit-grab;
        cursor: grab;
    }
    .has-scroll-dragging .c-scrollbar_thumb {
        cursor: -webkit-grabbing;
        cursor: grabbing;
    }
    [data-scroll-direction='horizontal'] .c-scrollbar_thumb {
        right: auto;
        bottom: 0;
    }
    
    //Cookie Consent
    .CookieConsent {
        a {
            color: ${({ theme }) => theme.colors.warmRed}
        }
    }
    .cookie-accept-button {
        transition: background 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        background: transparent!important;
        font-size: 1rem!important;
        line-height: 1rem!important;
        color: ${({ theme }) => theme.colors.sharkGrey}!important;
        border: 1px solid ${({ theme }) => theme.colors.sharkGrey}!important;
        border-radius: 2rem!important;
        padding: 0.75rem 1.5rem!important;
        margin: 1rem!important;
    }
    .cookie-accept-button:hover {
        border: 1px solid ${({ theme }) => theme.colors.warmRed}!important;
        background: ${({ theme }) => theme.colors.warmRed}!important;
        color: ${({ theme }) => theme.colors.white}!important;
    }
    
`;
